import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import SymptomBlock from 'gatsby-theme-gaviscon/src/components/SymptomBlock';
import Button from 'gatsby-theme-gaviscon/src/components/common/Button';
import ProductVariats from 'gatsby-theme-gaviscon/src/components/ProductVariats';
import ProductFormats from '../ProductFormats';
import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';
import IconCustom from 'gatsby-theme-gaviscon/src/components/common/IconCustom';
import useScreenRecognition from 'gatsby-theme-gaviscon/src/hooks/useScreenRecognition';
import './ProductDetails.scss';

const ProductDetails: FC<ProductPageTypes.IProductDetails> = ({
  productDetailsData,
}) => {
  const {
    descriptionShort,
    productImage,
    productImageAlt,
    symptoms,
    commonProductSettings: {
      availableFlavorsLabel,
      availableSizesLabel,
      chooseFormatLabel,
    },
    buyNow,
    btnLogo,
    btnLogoAlt,
    flavours,
    sizes,
    otherFormats,
    currentFormat,
    ariaBuyNow,
  } = productDetailsData;

  const { isNotMobile, isMobile } = useScreenRecognition();
  const path = (buyNow?.[0]?.url || '').concat(buyNow?.[0]?.queryString || '');
  const currentFormatType = currentFormat[0].properties.type[0];
  const similarFormats = otherFormats.filter(({ properties: { type } }) => type[0] === currentFormatType) || [];
  const realyOtherFormats = otherFormats.filter(({ properties: { type } }) => type[0] !== currentFormatType);

  return (
    <div className="product-details" data-testid="product-details">
      <Container fluid>
        <div className="product-details__grid">
          <div className="product-details__text">
            <DangerouslySetInnerHtml
              className="product-details__short-description"
              html={descriptionShort}
            />
            {symptoms?.length
              ? symptoms.map(({ properties: { text, color } }) => (
                <SymptomBlock key={text} text={text} color={color} />
              ))
              : null}
          </div>
          {productImage ? (
            <div className="product-details__image">
              {isNotMobile ? (
                <GatsbyImage
                  isLazyLoading={false}
                  style={{ overflow: 'initial' }}
                  objectFit="contain"
                  objectPosition="50% 100%"
                  className="product-details__image-img"
                  fluid={productImage}
                  alt={productImageAlt}
                />
              ) : null}
              {isMobile ? (
                <GatsbyImage
                  isLazyLoading={false}
                  style={{ overflow: 'initial' }}
                  objectFit="contain"
                  objectPosition="50% 100%"
                  className="product-details__image-img"
                  fluid={productImage}
                  alt={productImageAlt}
                  fadeIn={false}
                />
              ) : null}
            </div>
          ) : null}
        </div>
        <ProductFormats otherFormats={similarFormats} currentFormat={currentFormat} title={chooseFormatLabel} />
        {realyOtherFormats?.length ? (<ProductFormats otherFormats={realyOtherFormats} currentFormat={[]} title={availableSizesLabel} />) : null}
        {flavours?.length ? (
          <ProductVariats title={availableFlavorsLabel} variants={flavours} />
        ) : null}

        {buyNow?.length ? (
          <div className="product-details__buy">
            <Button to={path} target={buyNow[0].target} ariaLabel={ariaBuyNow} variant="buy">
              {buyNow[0].name}
              {btnLogo?.fallbackUrl ? (
                <GatsbyImage
                  isLazyLoading={false}
                  objectFit="contain"
                  className="product-details__btn-logo"
                  fluid={btnLogo}
                  alt={btnLogoAlt}
                />
              ) : (
                <IconCustom icon="chevron-right" />
              )}
            </Button>
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export default ProductDetails;
