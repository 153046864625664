import React, { FC } from 'react';

import IconCustom from 'gatsby-theme-gaviscon/src/components/common/IconCustom';
import './ProductVariats.scss';

const ProductVariats: FC<ProductPageTypes.IProductVariants> = ({ variants, title }) => (
  <div className="product-variants" data-testid="product-variants">
    <strong className="product-variants__title">{title}</strong>
    <ul className="product-variants__list">
      {variants.map(({ properties: { icon: [name], label } }) => (
        <li key={label} title={label} className="product-variants__list-item">
          <div className="product-variants__circle">
            <IconCustom icon={name} />
          </div>
          <span className="product-variants__list-label">{label}</span>
        </li>
      ))}
    </ul>
  </div>
);

export default ProductVariats;
